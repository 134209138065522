import {
  GET_API_ACCOUNTS,
  POST_API_ACCOUNT,
  PUT_API_ACCOUNT,
  GET_API_USERS,
  GET_API_USER,
  POST_API_USER,
  PUT_API_USER,
  DELETE_API_USER,
  POST_API_COLLABORATOR,
  PUT_API_TRANSFER_OWNERSHIP,
  GET_API_INTERVIEW_SESSIONS,
  GET_API_INTERVIEW_SESSION,
  DELETE_API_INTERVIEW_SESSION,
  GET_API_FITNEWSS_CRITERIA_LIST,
  GET_API_FITNEWSS_CRITERIA,
  POST_API_FITNEWSS_CRITERIA,
  PUT_API_FITNEWSS_CRITERIA,
  DELETE_API_FITNEWSS_CRITERIA,
  DELETE_API_CRITERIA,
  POST_API_CRITERIA,
  GET_API_INTERVIEW_SCRIPTS,
  GET_API_INTERVIEW_SCRIPT_DESIGN,
  GET_API_INTERVIEW_SCRIPT_QUESTIONS,
  DELETE_API_INTERVIEW_SCRIPT,
  POST_API_INTERVIEW_SCRIPT,
  PUT_API_INTERVIEW_SCRIPT_DESIGN,
  PUT_API_INTERVIEW_SCRIPT_QUESTION,
  PUT_API_CRITERIA,
  GET_API_INCENTIVE_LIST,
  PUT_API_INCENTIVE,
  POST_API_INTERVIEW_SCRIPT_DESIGN,
  POST_API_INTERVIEW_SCRIPT_QUESTION,
  GET_API_INTERVIEW_SCRIPT_OPTION,
  GET_API_INTERVIEW_SCRIPT_OPTIONS,
  POST_API_INTERVIEW_SCRIPT_OPTION,
  DELETE_API_INTERVIEW_SCRIPT_OPTION,
  PUT_API_INTERVIEW_SCRIPT_OPTION,
  DELETE_API_INTERVIEW_SCRIPT_QUESTION,
  POST_API_INCENTIVE,
  GET_API_TOKEN,
  REFRESH_TOKEN,
  UPDATE_SLATE_ACTIVATE,
  START_TEST_DRIVE,
  ADVANCE_TEST_DRIVE,
  GET_TRANSCRIPTS,
  GET_TRANSCRIPT,
  POST_TRANSCRIPT,
  UDPATE_TRANSCRIPT,
  CREATE_PROSPECT_SESSION,
  GET_ONBOARD_TRANSCRIPT,
  POST_ONBOARD_TRANSCRIPT,
  UDPATE_ONBOARD_TRANSCRIPT,
  UDPATE_COMPLETED_ONBOARD_TRANSCRIPT,
  FINISH_TRANSCRIPT,
  CREATE_ONBOARD_PROSPECT_SESSION,
  CREATE_ACCOUNT,
  SEND_RESET_PASSWORD,
  SET_NEWPASSWORD,
  RESEND_VERIFY_EMAIL,
  PUT_WELCOME,
  GET_SLATES,
  GET_SLATE,
  GET_SLATE_URL_CODE,
  ADD_SLATE,
  DELETE_SLATE,
  UPDATE_SLATE,
  GET_INTERVIEW,
  SAVE_INTERVIEW,
  GET_QUESTION,
  GET_QUESTION_QUERY,
  ADD_QUESTION,
  DELETE_QUESTION,
  UPDATE_QUESTION,
  GET_TIMEZONE,
  GET_COUNTRY,
  PUT_ONE_COMPANY,
  GET_ONE_COMPANY,
  UPLOAD_COMPANY_LOGO,
  DELETE_ANSWER,
  POST_INTERVIEW_INCENTIVE,
  PUT_INTERVIEW_INCENTIVE,
  GET_INTERVIEW_INCENTIVE,
  DELETE_INTERVIEW_INCENTIVE,
  POST_API_ADMIN_LOGIN,
  GET_API_COMPANIES,
  DELETE_API_ACCOUNT,
  DELETE_API_ADMIN_USER,
  POST_API_SPOOF_TOKENS,
  POST_API_COLLABORATOR_ADMIN,
  PUT_API_TRANSFER_OWNERSHIP_ADMIN,
} from "./type";

import ApiServices from "../services/apiServices";
import { deleteCookie, getCookie, setCookie } from "../config/common";

export const createAccount = (email, company, password) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.createAccount(email, company, password);
      dispatch({
        type: CREATE_ACCOUNT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const resendVerifyEmail = (code) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.resendVerifyEmail(code);
      dispatch({
        type: RESEND_VERIFY_EMAIL,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      if (err.status !== 400) return err.response;
    }
  };
};

export const getTokenData = (email, password) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getToken(email, password);
      dispatch({
        type: GET_API_TOKEN,
        payload: res.data,
      });

      setCookie(
        `access_${email}`,
        res.data.access_token,
        res.data.access_token_expires_at
      );
      setCookie(
        `refresh_${email}`,
        res.data.refresh_token,
        res.data.refresh_token_expires_at
      );

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const refreshToken = () => {
  if (!getCookie("refresh_token") || getCookie("refresh_token") === null) {
    window.location.href = "/creator-dashboard/login";
    return;
  }
  if (getCookie("access_token") || getCookie("access_token") !== null) return;

  return async (dispatch) => {
    try {
      const res = await ApiServices.getAuthRefreshToken();

      setCookie(
        "access_token",
        res.data.access_token,
        res.data.access_token_expires_at
      );
      if (res.data.refresh_token)
        setCookie(
          "refresh_token",
          res.data.refresh_token,
          res.data.refresh_token_expires_at
        );
      dispatch({
        type: REFRESH_TOKEN,
        payload: res.data,
      });

      return res.data.access_token;
    } catch (err) {
      deleteCookie("access_token");
      deleteCookie("refresh_token");
      window.location.href = "/creator-dashboard/login";
    }
  };
};

export const putWelcome = (user_id, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.putWelcome(user_id, obj, access_token);
      dispatch({
        type: PUT_WELCOME,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      if (err.status !== 400) return err.response;
    }
  };
};

export const setNewPassword = (code, email, password) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.setNewPassword(code, email, password);
      dispatch({
        type: SET_NEWPASSWORD,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const sendResetPassword = (email) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.sendResetPassword(email);
      dispatch({
        type: SEND_RESET_PASSWORD,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

// ===========  Slates =============
export const getSlate = (id) => {
  let access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getSlate(id, access_token);
      dispatch({
        type: GET_SLATE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getSlateUrlCode = (id) => {
  let access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getSlateUrlCode(id, access_token);
      dispatch({
        type: GET_SLATE_URL_CODE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getSlates = (page = 0, rows = 10, query = "") => {
  let access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getSlates(page, rows, query, access_token);
      dispatch({
        type: GET_SLATES,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const addSlate = (name) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.addSlate(name, access_token);
      dispatch({
        type: ADD_SLATE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateSlate = (id, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.updateSlate(id, obj, access_token);
      dispatch({
        type: UPDATE_SLATE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getQuestion = (interviewId) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getQuestion(interviewId, access_token);
      dispatch({
        type: GET_QUESTION,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getQuestionQuery = (interviewId, questionId) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getQuestionQuery(
        interviewId,
        questionId,
        access_token
      );
      dispatch({
        type: GET_QUESTION_QUERY,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const addQuestion = (interviewId, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.addQuestion(interviewId, obj, access_token);
      dispatch({
        type: ADD_QUESTION,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateSlateActivate = (slateId) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.updateSlateActivate(slateId, access_token);
      dispatch({
        type: UPDATE_SLATE_ACTIVATE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const startTestDrive = (url_code) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.startTestDrive(url_code, access_token);
      dispatch({
        type: START_TEST_DRIVE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const advanceTestDrive = (url_code, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.advanceTestDrive(
        url_code,
        obj,
        access_token
      );
      dispatch({
        type: ADVANCE_TEST_DRIVE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const postTranscript = (url_code, obj) => {
  const session_token = getCookie(`prospect_${url_code}`);

  return async (dispatch) => {
    try {
      const res = await ApiServices.postTranscript(
        url_code,
        obj,
        session_token
      );
      dispatch({
        type: POST_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getTranscripts = (interview_id, query = "") => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getTranscripts(
        interview_id,
        query,
        access_token
      );
      dispatch({
        type: GET_TRANSCRIPTS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getTranscript = (interview_id, transcripts_id) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getTranscript(
        interview_id,
        transcripts_id,
        access_token
      );
      dispatch({
        type: GET_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateTranscript = (url_code, transcript_id, obj) => {
  const session_token = getCookie(`prospect_${url_code}`);

  return async (dispatch) => {
    try {
      const res = await ApiServices.updateTranscript(
        url_code,
        transcript_id,
        obj,
        session_token
      );
      dispatch({
        type: UDPATE_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const createProspectSession = (url_code) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.createProspectSession(url_code);

      setCookie(
        `prospect_${url_code}`,
        res.data.session_token,
        res.data.session_token_expires_at
      );
      dispatch({
        type: CREATE_PROSPECT_SESSION,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getOnboardTranscript = (interview_id) => {
  const access_token = getCookie(`access_token`);

  return async (dispatch) => {
    try {
      const res = await ApiServices.getOnboardTranscript(
        interview_id,
        access_token
      );
      dispatch({
        type: GET_ONBOARD_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const postOnboardTranscript = (interview_id, obj) => {
  const access_token = getCookie("access_token");
  const session_token = getCookie(`onboard_${interview_id}`);

  return async (dispatch) => {
    try {
      const res = await ApiServices.postOnboardTranscript(
        interview_id,
        obj,
        access_token,
        session_token
      );
      dispatch({
        type: POST_ONBOARD_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateOnboardTranscript = (interview_id, transcript_id, obj) => {
  const access_token = getCookie("access_token");
  const session_token = getCookie(`onboard_${interview_id}`);

  return async (dispatch) => {
    try {
      const res = await ApiServices.updateOnboardTranscript(
        interview_id,
        transcript_id,
        obj,
        access_token,
        session_token
      );
      dispatch({
        type: UDPATE_ONBOARD_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateCompletedOnboardTranscript = (
  interview_id,
  transcript_id,
  obj
) => {
  const access_token = getCookie("access_token");
  const session_token = getCookie(`onboard_${interview_id}`);

  return async (dispatch) => {
    try {
      const res = await ApiServices.updateCompletedOnboardTranscript(
        interview_id,
        transcript_id,
        obj,
        access_token,
        session_token
      );
      dispatch({
        type: UDPATE_COMPLETED_ONBOARD_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const finishTranscript = (
  interview_id,
  transcript_id,
  email,
  url_code = ""
) => {
  const session_token = getCookie(`onboard_${interview_id}`)
    ? getCookie(`onboard_${interview_id}`)
    : getCookie(`prospect_${url_code}`);

  return async (dispatch) => {
    try {
      const res = await ApiServices.finishTranscript(
        transcript_id,
        email,
        session_token
      );
      dispatch({
        type: FINISH_TRANSCRIPT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const createOnboardProspectSession = (slate_id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.createOnboardProspectSession(slate_id);

      const res_slate = await ApiServices.getSlate(
        slate_id,
        getCookie("access_token")
      );

      setCookie(
        `onboard_${res_slate.data.payloads[0].onboarding_interview_id}`,
        res.data.session_token,
        res.data.session_token_expires_at
      );
      dispatch({
        type: CREATE_ONBOARD_PROSPECT_SESSION,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateQuestion = (interviewId, questionId, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.updateQuestion(
        interviewId,
        questionId,
        obj,
        access_token
      );
      dispatch({
        type: UPDATE_QUESTION,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const deleteQuestion = (interviewId, questionId) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteQuestion(
        interviewId,
        questionId,
        access_token
      );
      dispatch({
        type: DELETE_QUESTION,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const deleteSlate = (id) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteSlate(id, access_token);
      dispatch({
        type: DELETE_SLATE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getInterview = (sId, interviewId) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterview(
        sId,
        interviewId,
        access_token
      );
      dispatch({
        type: GET_INTERVIEW,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const saveInterview = (sId, interviewId, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.saveInterview(
        sId,
        interviewId,
        obj,
        access_token
      );
      dispatch({
        type: SAVE_INTERVIEW,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

// ===========  Accounts =============

export const receiveAccountsData = (pages = 1, rows = 10) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getAccountsData(pages - 1, rows);
      dispatch({
        type: GET_API_ACCOUNTS,
        payload: res.data,
        pages: pages - 1,
        rows: rows,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getAccountData = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.getAccount(id);
      dispatch({
        type: GET_API_USER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const addAccountData = (obj) => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.postAccount(obj, access_token);
      dispatch({
        type: POST_API_ACCOUNT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const deleteAccount = (id) => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteAccount(id, access_token);
      dispatch({
        type: DELETE_API_ACCOUNT,
        payload: res,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const deleteAdminUser = (id) => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteAdminUser(id, access_token);
      dispatch({
        type: DELETE_API_ADMIN_USER,
        payload: res,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateAccountData = (id, obj) => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.putAccount(id, obj, access_token);
      dispatch({
        type: PUT_API_ACCOUNT,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

// ===========  Users =============

export const getUsersData = (company_id, pages = 1, rows = 10) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getUsers(
        company_id,
        pages - 1,
        rows,
        access_token
      );
      dispatch({
        type: GET_API_USERS,
        payload: res.data,
        pages: pages - 1,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getUserData = (company_id, id) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getUser(company_id, id, access_token);
      dispatch({
        type: GET_API_USER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const addUserData = (company_id, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.postUser(company_id, obj, access_token);
      dispatch({
        type: POST_API_USER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const updateUserData = (company_id, id, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.putUser(company_id, id, obj, access_token);
      dispatch({
        type: PUT_API_USER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const deleteUserData = (company_id, id) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteUser(company_id, id, access_token);
      dispatch({
        type: DELETE_API_USER,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const addCollaborator = (company_id, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.postCollaborator(
        company_id,
        obj,
        access_token
      );
      dispatch({
        type: POST_API_COLLABORATOR,
        payload: {
          ...res.data,
          company_id,
        },
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const transferOwnership = (company_id, collaborator_id, owner_id) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.transferOwnership(
        company_id,
        collaborator_id,
        owner_id,
        access_token
      );
      dispatch({
        type: PUT_API_TRANSFER_OWNERSHIP,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

// ===========  GeoTime =============

export const getTimezones = () => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getTimezones(access_token);
      dispatch({
        type: GET_TIMEZONE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getCountries = () => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getCountries(access_token);
      dispatch({
        type: GET_COUNTRY,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

// ===========  Company =============

export const getOneCompany = (id) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getOneCompany(id, access_token);
      dispatch({
        type: GET_ONE_COMPANY,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const putOneCompany = (id, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.putOneCompany(id, obj, access_token);
      dispatch({
        type: PUT_ONE_COMPANY,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const uploadLogo = (id, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.uploadLogo(id, obj, access_token);
      dispatch({
        type: UPLOAD_COMPANY_LOGO,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const deleteAnswer = (id) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteAnswer(id, access_token);
      dispatch({
        type: DELETE_ANSWER,
        payload: res.data,
      });
    } catch (err) {
      return err.response;
    }
  };
};

export const postInterviewIncentive = (interviewId, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.postInterviewIncentive(
        interviewId,
        obj,
        access_token
      );
      dispatch({
        type: POST_INTERVIEW_INCENTIVE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const putInterviewIncentive = (interviewId, incentiveId, obj) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.putInterviewIncentive(
        interviewId,
        incentiveId,
        obj,
        access_token
      );
      dispatch({
        type: PUT_INTERVIEW_INCENTIVE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const getInterviewIncentive = (interviewId, incentiveId) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.getInterviewIncentive(
        interviewId,
        incentiveId,
        access_token
      );
      dispatch({
        type: GET_INTERVIEW_INCENTIVE,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const deleteInterviewIncentive = (interviewId, incentiveId) => {
  const access_token = getCookie("access_token");

  return async (dispatch) => {
    try {
      const res = await ApiServices.deleteInterviewIncentive(
        interviewId,
        incentiveId,
        access_token
      );
      dispatch({
        type: DELETE_INTERVIEW_INCENTIVE,
        payload: res.data,
      });

      return res;
    } catch (err) {
      return err.response;
    }
  };
};

// ===========  Admin =============

export const adminLogin = (email, password) => {
  return async (dispatch) => {
    try {
      const res = await ApiServices.adminLogin(email, password);
      dispatch({
        type: POST_API_ADMIN_LOGIN,
        payload: res.data,
      });

      setCookie(
        `access_${email}`,
        res.data.access_token,
        res.data.access_token_expires_at
      );
      setCookie(
        `refresh_${email}`,
        res.data.refresh_token,
        res.data.refresh_token_expires_at
      );

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const receiveCompaniesData = (pages = 0, rows = 10, query = "") => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.getCompaniesData(
        pages,
        rows,
        query,
        access_token
      );
      dispatch({
        type: GET_API_COMPANIES,
        payload: res.data,
        pages: pages,
        rows: rows,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const spoofTokens = (company_id, email) => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.spoofTokens(company_id, access_token);
      dispatch({
        type: POST_API_SPOOF_TOKENS,
        payload: res.data,
      });

      setCookie(
        `access_${email}`,
        res.data.access_token,
        res.data.access_token_expires_at
      );
      setCookie(
        `refresh_${email}`,
        res.data.refresh_token,
        res.data.refresh_token_expires_at
      );

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const addCollaboratorAdmin = (company_id, obj) => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.postCollaboratorAdmin(
        company_id,
        obj,
        access_token
      );
      dispatch({
        type: POST_API_COLLABORATOR_ADMIN,
        payload: {
          ...res.data,
          company_id,
        },
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};

export const transferOwnershipAdmin = (collaborator_id, owner_id) => {
  const access_token = getCookie("access_token", true);

  return async (dispatch) => {
    try {
      const res = await ApiServices.transferOwnershipAdmin(
        collaborator_id,
        owner_id,
        access_token
      );
      dispatch({
        type: PUT_API_TRANSFER_OWNERSHIP_ADMIN,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      return err.response;
    }
  };
};
