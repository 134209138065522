import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "./../../assets/images/Perceptive_Panda_Logo_light.png";
import { useDispatch } from "react-redux";
import Button from "../../components/basics/button";
import { adminLogin } from "../../action/api";
import { getCookie } from "../../config/common";
import {
  center_pos,
  font_text_sm_Regular,
  font_dispaly_xs_Semibold,
  eye_style,
  globalColor,
} from "../../assets/variable/global";
import useToken from "./useToken";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [flag, setFlag] = useState(-1);
  const dispatch = useDispatch();
  const [errText, setErrText] = useState("");
  const [eyeStatus, setEyeStatus] = useState(false);
  const { setToken } = useToken();
  const [apiStatus, setApiStatus] = useState(200);
  useEffect(() => {
    if (
      getCookie("access_token") !== null &&
      getCookie("access_token") !== ""
    ) {
      window.location.href = "/mission-control/companies";
    } else {
      if (
        getCookie("refresh_token") !== "" &&
        getCookie("refresh_token") !== null
      )
        window.location.href = "/mission-control/companies";
    }
  }, []);
  useEffect(() => {
    if (flag === 0) {
      if (password.length < 8 || !validateEmail(email)) setFlag(1);
    }
  }, [flag]);
  useEffect(() => {
    if (password.length > 0) {
      if (password.length < 8 || !validateEmail(email)) setFlag(1);
      else setFlag(0);
    }
  }, [password, email]);
  function submitFunc() {
    if (password.length >= 8 && validateEmail(email)) {
      adminLogin(
        email,
        password
      )(dispatch).then((res) => {
        if (res && res.user) {
          const user = res.user;

          setToken(user);
          window.location.href = "/mission-control/companies";
        } else {
          if (res && res.status && res.status === 400) {
            if (res.data.message.includes("record not found")) {
              setApiStatus(400);
              setErrText("We don't see an account with that email address?");
            } else setErrText(res.statusText);
            setFlag(1);
          } else if (res && res.status && res.status === 401) {
            if (res.data.message.includes("not authenticated")) {
              setApiStatus(401);
              setErrText("That's so not your password--please try again");
            } else setErrText(res.statusText);
            setFlag(1);
          }
          return;
        }
      });
    }
  }
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  React.useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && email !== "" && password !== "") {
        event.preventDefault();
        submitFunc();
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  return (
    <LoginWrapper>
      <LeftSide>
        <img src={logo} alt="Sign Logo Img" style={center_pos} />
      </LeftSide>
      <RightSide>
        <form style={center_pos}>
          <h3 style={font_dispaly_xs_Semibold}>MISSION CONTROL</h3>
          <p style={font_text_sm_Regular}>Administrator Account</p>
          <div className="form-group">
            <label htmlFor={"modal_title"}>Email</label>
            <input
              type="email"
              className={`form-control ${flag === 1 && (!validateEmail(email) || apiStatus === 400) && "form-validation"}`}
              id="modal_title"
              aria-describedby="modal_title_input"
              placeholder="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {flag === 1 && errText !== "" && apiStatus === 400 && (
              <p className="required-html">{errText}</p>
            )}
          </div>
          <div className="form-group" style={{ position: "relative" }}>
            <label htmlFor={"modal_title"}>Password</label>
            <input
              type={eyeStatus ? "text" : "password"}
              className={`form-control ${flag === 1 && (password.length < 8 || apiStatus === 401) && "form-validation"}`}
              id="modal_title_password"
              aria-describedby="modal_title_input"
              placeholder="Enter password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {password.length > 0 && (
              <i
                className={eyeStatus ? "far fa-eye-slash" : "far fa-eye"}
                style={eye_style}
                onClick={() => {
                  setEyeStatus(!eyeStatus);
                }}
              ></i>
            )}
            {flag === 1 && password.length < 8 && (
              <p className="required-html">
                Our favorite passwords are 8 characters or more
              </p>
            )}
            {flag === 1 && errText !== "" && apiStatus === 401 && (
              <p className="required-html">{errText}</p>
            )}
          </div>
          <div className="modal-footer-btn">
            {/* <Button title="Cancel" outline onClickBtn={closeModal} /> */}
            <Button
              title={"Sign In"}
              disable={flag === 1 || flag === -1}
              width="full"
              onClickBtn={() => {
                submitFunc();
              }}
            />
          </div>
        </form>
      </RightSide>
    </LoginWrapper>
  );
}

const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  form {
    width: 100%;
  }
  form .form-control.form-validation {
    border-color: red;
    margin-bottom: 3px;
  }
  form .form-group {
    label {
      color: #344054;
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      margin-bottom: 6px;
    }
    input,
    textarea {
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #667085;
      font-family: Figtree;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-bottom: 16px;
    }
  }
`;
const LeftSide = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  background-color: ${globalColor.primary_800};
  min-height: 100vh;
  height: 100%;
  img {
    display: block;
    max-width: 400px;
  }
`;
const RightSide = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  background-color: ${globalColor.gray_200};
  min-height: 100vh;
  height: 100%;
  form {
    max-width: 400px;
    width: 100%;
    padding: 20px;
  }
  .required-html {
    color: ${globalColor.error_600};
    font-size: 14px;
    line-height: 24px;
  }
`;
