import {
  GET_API_COMPANIES,
  GET_API_USERS,
  POST_API_ACCOUNT,
  POST_API_COLLABORATOR,
  PUT_API_ACCOUNT,
} from "../action/type";

const initialState = {
  account_page: 0,
  account_total: 0,
  account_limit: 10,
  account_order: "asc",
  accounts: [],
  user_page: 0,
  user_total: 0,
  user_limit: 10,
  user: [],
};

function apiReducer(apis = initialState, action) {
  const { type, payload, pages } = action;
  switch (type) {
    case GET_API_COMPANIES:
      apis.accounts = [];
      apis.account_total = payload.total;
      payload.payloads?.forEach((element) => {
        const i = apis.accounts?.findIndex((d) => d.id === element.id);
        if (i < 0) {
          apis.accounts.push(element);
        } else {
          // apis.accounts.push(element);
          // apis.accounts[i] = element;
        }
      });

      return {
        ...apis,
        // account_page: pages,
        // account_total: payload.total - 1,
        // accounts: [...payload.payloads.filter(user  => user.users[0].roles[0] !== "SUPER_ADMIN")],
      };
    case POST_API_COLLABORATOR:
      const { company_id, payloads } = payload;
      const acc = apis.accounts.find((a) => a.id === company_id);

      acc.users.push({
        email: payloads[0].user_email,
        id: payloads[0].code,
        roles: ["CREATOR"],
      });

      return {
        ...apis,
      };
    case POST_API_ACCOUNT:
      return {
        ...apis,
      };
    case PUT_API_ACCOUNT:
      let temp_accounts = [...apis.accounts];
      for (let i = 0; i < temp_accounts.length; i++) {
        if (temp_accounts[i].id === payload.data.objects[0].id) {
          temp_accounts[i] = { ...payload.data.objects[0] };
          break;
        }
      }

      return {
        ...apis,
        accounts: [...temp_accounts],
      };
    default:
      return apis;
  }
}

export default apiReducer;
