import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import useToken from "./pages/auth/useToken";
import Sidebar from "./components/client/sidebar";

import Signup from "./pages/client/auth/signup";
import Login from "./pages/client/auth/login";
import Verify from "./pages/client/auth/verify";
import VerifiedSignin from "./pages/client/auth/verifiedSignin";
import ForgotPassword from "./pages/client/auth/forgot-password";
import SmartInterviews from "./pages/client/smartInterviews";
import Settings from "./pages/client/settings/settings";

import Accounts from "./pages/accounts";
import MLogin from "./pages/auth/login";
import MForgotPassword from "./pages/auth/forgot-password";
import MSidebar from "./components/sidebar";
import MSignup from "./pages/auth/signup";
import MVerify from "./pages/auth/verify";
import MVerifiedSignin from "./pages/auth/verifiedSignin";
import ResetPassword from "./pages/client/auth/ResetPassword/reset-password";
import NotFound from "./pages/notFound";
import UnauthorizedAccess from "./pages/401.js";
import SeverError from "./pages/500.js";
import EditInterview from "./pages/client/smartInterview/editInterview";
import AnswerText from "./pages/emerald/answerText";
import Thankyou from "./pages/emerald/thankyou";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/mission-control" exact element={<MLogin />} />
          <Route path="/mission-control/verify" element={<MVerify />} />
          <Route path="/mission-control/signup" element={<MSignup />} />
          <Route
            path="/mission-control/verify-sign"
            element={<MVerifiedSignin />}
          />
          <Route path="/mission-control/login" element={<MLogin />} />
          <Route
            path="/mission-control/forgot-password"
            element={<MForgotPassword />}
          />
          <Route
            path="/mission-control/companies"
            element={
              <div className="container-fluid">
                <div className="row flex-nowrap">
                  <div className="col-auto px-0 bg-sidebar">
                    <MSidebar />
                  </div>
                  <div className="col body p-0">
                    <Accounts />
                  </div>
                </div>
              </div>
            }
          />
          {/* Client Auth */}
          <Route path="/" exact element={<Login />} />
          <Route path="/creator-dashboard" exact element={<Login />} />
          <Route path="/creator-dashboard/login" exact element={<Login />} />
          <Route path="/creator-dashboard/verify" element={<Verify />} />
          <Route
            path="/creator-dashboard/create-account"
            element={<Signup />}
          />
          <Route
            path="/creator-dashboard/verify-signin"
            element={<VerifiedSignin />}
          />
          <Route
            path="/creator-dashboard/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/creator-dashboard/reset-password/:email/:code"
            element={<ResetPassword />}
          />
          {/* Client  Main Page */}
          <Route
            path="/creator-dashboard/smart-interviews"
            element={
              <div className="container-fluid">
                <div className="row flex-nowrap">
                  <div className="col-auto px-0 bg-sidebar">
                    <Sidebar />
                  </div>
                  <div className="col body p-0">
                    <SmartInterviews />
                  </div>
                </div>
              </div>
            }
          />
          <Route
            path="/creator-dashboard/settings"
            element={
              <div className="container-fluid">
                <div className="row flex-nowrap">
                  <div className="col-auto px-0 bg-sidebar">
                    <Sidebar />
                  </div>
                  <div className="col body p-0">
                    <Settings />
                  </div>
                </div>
              </div>
            }
          />
          <Route
            path="/creator-dashboard/smart-interviews/:id"
            element={
              <div className="container-fluid">
                <div className="row flex-nowrap">
                  <div className="col-auto px-0 bg-sidebar">
                    <Sidebar />
                  </div>
                  <div className="col body p-0">
                    <EditInterview />
                  </div>
                </div>
              </div>
            }
          />
          <Route path="/smartinterviews/:id" element={<AnswerText />} />
          <Route path="/smartinterviews/thankyou/:id" element={<Thankyou />} />
          <Route path="/401" element={<UnauthorizedAccess />} />
          <Route path="/500" element={<SeverError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
