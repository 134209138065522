import React from "react";
import styled from "styled-components";
import arrow_left from "./../assets/images/arrow-left.png";
import Button from "./basics/button";
import ButtonGroup from "./basics/buttongroup";
import { display_sm } from "../assets/variable/global";

export default function AccountsHeader(props) {
  const { title, subItem, onPrevMain, preview, tag, onAddAccount } = props;

  return (
    <HeaderWrapper>
      <div className="sub-page header">
        <div className="sub-btns">
          <div className="sub-title">
            <h4>{title}</h4>
          </div>
          <div className="btn-groups">
            <div className="button-copy" onClick={() => onAddAccount()}>
              <i className="fa fa-plus" aria-hidden="true"></i>Add Account
            </div>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  background: white;
  display: flex;
  padding: 20px 32px;
  padding-right: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  .account-header {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1065px;
    width: 100%;
    .add-account-btn {
      cursor: pointer;
      padding: 10px 16px;
      border-radius: 8px;
      border: 1px solid var(--primary-600, #1693c7);
      background: var(--primary-600, #1693c7);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      i {
        margin-right: 5px;
      }
      color: white;
    }
  }
  h2 {
    color: #101828;
    font-family: Figtree;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
  }
  .sub-page.header {
    width: 100%;
    flex-direction: inherit;
    min-width: 562px;
    h2 {
      color: #101828;
      font-family: Figtree;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
    p {
      color: #475467;
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .btn-wrapper {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #eaecf0;
      background: #f9fafb;
      padding: 4px;
      .btn-group {
        .btn {
          font-family: Figtree;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          border: 0px;
          &.active,
          &:focus,
          &:hover {
            color: #344054;
            font-family: Figtree;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            border-radius: 6px;
            background: #fff;
            box-shadow:
              0px 1px 2px 0px rgba(16, 24, 40, 0.06),
              0px 1px 3px 0px rgba(16, 24, 40, 0.1);
          }
          margin-right: 4px;
        }
      }
    }
  }
  .sub-page {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1316px;
    .sub-title {
      display: flex;
      img {
        width: 24px;
        height: 22px;
        margin-right: 8px;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
      }
      h4 {
        font-family: Figtree;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 2px;
      }
      margin-bottom: 8px;
    }
    .sub-badges {
      display: flex;
      .badge {
        margin-right: 8px;
      }
    }
  }
  .badge {
    display: flex;
    padding: 2px 10px 2px 8px;
    height: 24px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    mix-blend-mode: multiply;
    background: #e7f7ff;
    border: 1px solid #caebf9;
    color: #000;
    i {
      color: #3fabd7;
    }
    span {
      color: #0b5987;
    }
  }
  .btn-groups {
    .button-copy {
      background: #1693c7;
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-family: Figtree;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      i {
        margin-right: 8px;
      }
      &.email {
        background: white;
        color: #344054;
        border: 1px solid #d0d5dd;
        margin-right: 10px;
      }
    }
  }
  .sub-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
  }
  p {
    margin: 0px !important;
  }
`;
