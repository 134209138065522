import React, { useState } from "react";
import styled from "styled-components";
import Button from "../basics/button";

export default function SaveCancelModal(props) {
  const { closeModal, saveModal, title, description, name = "" } = props;
  const [flag, setFlag] = useState(-1);

  return (
    <CancelModalWrapper>
      <div className="back-gray"></div>
      <div
        className="modal fade show"
        tabIndex={-1}
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <form>
            <div className="modal-content">
              <div className="modal-header">
                <div
                  onClick={closeModal}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ cursor: "pointer" }}
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>
              <div className="modal-body">
                <h3 style={{ textAlign: "center" }}>Save settings?</h3>
                <p style={{ textAlign: "center" }}>
                  If you leave this page without saing your changes will <br />{" "}
                  be discarded and your previous settings will be restored.
                </p>
              </div>
              <div className="modal-footer">
                <div className="modal-footer-btn">
                  <Button
                    title={"Discard Changes"}
                    outline
                    onClickBtn={() => {
                      // deleteSlate(slate.id)(dispatch)
                      //   .then((res) => {})
                      //   .catch((err) => {});
                      setFlag(0);
                      saveModal(1);
                    }}
                  />
                  <Button
                    icon={"save"}
                    title={"Save Settings"}
                    onClickBtn={() => {
                      // submitFunc();
                      closeModal();
                      saveModal(2);
                      //saveModal(2);
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </CancelModalWrapper>
  );
}

const CancelModalWrapper = styled.div`
  position: relative;
  .back-gray {
    position: fixed;
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
  .modal {
    max-width: 1140px;
    width: calc(100% - 280px);
    margin-left: 280px;
    .modal-dialog {
      justify-content: center;
    }
  }
  .modal.fade {
    h3 {
      color: var(--gray-900, #101828);
      font-family: Figtree;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
    p {
      color: var(--gray-600, #475467);
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      margin-bottom: 0px;
    }
    .modal-footer {
      display: block;
      border: 0px;
      padding-top: 0px;
      h3 {
        color: var(--gray-900, #101828);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      p {
        color: var(--gray-600, #475467);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .modal-footer-btn {
        display: flex;
        justify-content: center;
        .btn-red {
          background: #d92d20;
        }
      }
    }
    .button-copy {
      background: #d92d20;
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-family: Figtree;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      i {
        margin-right: 8px;
      }
      &.email {
        background: white;
        color: #344054;
        border: 1px solid #d0d5dd;
        margin-right: 10px;
      }
    }
  }
  p.required-html {
    color: red !important;
    font-size: 12px;
  }
  .modal-header {
    border-bottom: 0px;
    padding: 5px 10px;
    justify-content: end;
  }
  .modal-body {
    padding-top: 0px;
  }
`;
