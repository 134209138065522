export const GET_API_ACCOUNT = "GET_API_ACCOUNT";
export const GET_API_ACCOUNTS = "GET_API_ACCOUNTS";
export const POST_API_ACCOUNT = "POST_API_ACCOUNT";
export const PUT_API_ACCOUNT = "PUT_API_ACCOUNT";
export const DELETE_API_ACCOUNT = "DELETE_API_ACCOUNT";
export const DELETE_API_ADMIN_USER = "DELETE_API_ADMIN_USER";

export const GET_API_USER = "GET_API_USER";
export const GET_API_USERS = "GET_API_USERS";
export const POST_API_USER = "POST_API_USER";
export const PUT_API_USER = "PUT_API_USER";
export const DELETE_API_USER = "DELETE_API_USER";
export const POST_API_COLLABORATOR = "POST_API_COLLABORATOR";
export const PUT_API_TRANSFER_OWNERSHIP = "PUT_API_TRANSFER_OWNERSHIP";

export const GET_API_INTERVIEW_SESSIONS = "GET_API_INTERVIEW_SESSIONS";
export const GET_API_INTERVIEW_SESSION = "GET_API_INTERVIEW_SESSION";
export const DELETE_API_INTERVIEW_SESSION = "DELETE_API_INTERVIEW_SESSION";

export const GET_API_FITNEWSS_CRITERIA_LIST = "GET_API_FITNEWSS_CRITERIA_LIST";
export const GET_API_FITNEWSS_CRITERIA = "GET_API_FITNEWSS_CRITERIA";
export const POST_API_FITNEWSS_CRITERIA = "POST_API_FITNEWSS_CRITERIA";
export const POST_API_CRITERIA = "POST_API_CRITERIA";
export const PUT_API_CRITERIA = "PUT_API_CRITERIA";
export const PUT_API_FITNEWSS_CRITERIA = "PUT_API_FITNEWSS_CRITERIA";
export const DELETE_API_FITNEWSS_CRITERIA = "DELETE_API_FITNEWSS_CRITERIA";
export const DELETE_API_CRITERIA = "DELETE_API_CRITERIA";
export const GET_API_INTERVIEW_SCRIPTS = "GET_API_INTERVIEW_SCRIPTS";
export const DELETE_API_INTERVIEW_SCRIPT = "DELETE_API_INTERVIEW_SCRIPT";

export const GET_API_INCENTIVE = "GET_API_INCENTIVE";
export const PUT_API_INCENTIVE = "PUT_API_INCENTIVE";
export const POST_API_INCENTIVE = "POST_API_INCENTIVE";
export const DELETE_API_INCENTIVE = "DELETE_API_INCENTIVE";
export const GET_API_INCENTIVE_LIST = "GET_API_INCENTIVE_LIST";

export const GET_API_TOKEN = "GET_API_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const SET_NEWPASSWORD = "SET_NEWPASSWORD";
export const RESEND_VERIFY_EMAIL = "RESEND_VERIFY_EMAIL";
export const PUT_WELCOME = "PUT_WELCOME";
export const GET_SLATES = "GET_SLATES";
export const GET_SLATE = "GET_SLATE";
export const GET_SLATE_URL_CODE = "GET_SLATE_URL_CODE";
export const ADD_SLATE = "ADD_SLATE";
export const DELETE_SLATE = "DELETE_SLATE";
export const SAVE_INTERVIEW = "SAVE_INTERVIEW";
export const GET_INTERVIEW = "GET_INTERVIEW";
export const UPDATE_SLATE = "UPDATE_SLATE";
export const UPDATE_SLATE_ACTIVATE = "UPDATE_SLATE_ACTIVATE";
export const START_TEST_DRIVE = "START_TEST_DRIVE";
export const ADVANCE_TEST_DRIVE = "ADVANCE_TEST_DRIVE";
export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTION_QUERY = "GET_QUESTION_QUERY";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const ADD_QUESTION = "ADD_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const GET_TRANSCRIPTS = "GET_TRANSCRIPTS";
export const GET_TRANSCRIPT = "GET_TRANSCRIPT";
export const POST_TRANSCRIPT = "POST_TRANSCRIPT";
export const UDPATE_TRANSCRIPT = "UDPATE_TRANSCRIPT";
export const CREATE_PROSPECT_SESSION = "CREATE_PROSPECT_SESSION";

export const GET_ONBOARD_TRANSCRIPT = "GET_ONBOARD_TRANSCRIPT";
export const POST_ONBOARD_TRANSCRIPT = "POST_ONBOARD_TRANSCRIPT";
export const UDPATE_ONBOARD_TRANSCRIPT = "UDPATE_ONBOARD_TRANSCRIPT";
export const UDPATE_COMPLETED_ONBOARD_TRANSCRIPT =
  "UDPATE_COMPLETED_ONBOARD_TRANSCRIPT";
export const FINISH_TRANSCRIPT = "FINISH_TRANSCRIPT";
export const CREATE_ONBOARD_PROSPECT_SESSION =
  "CREATE_ONBOARD_PROSPECT_SESSION";

export const GET_TIMEZONE = "GET_TIMEZONE";
export const GET_COUNTRY = "GET_COUNTRY";

export const GET_ONE_COMPANY = "GET_ONE_COMPANY";
export const PUT_ONE_COMPANY = "PUT_ONE_COMPANY";
export const UPLOAD_COMPANY_LOGO = "UPLOAD_COMPANY_LOGO";

export const DELETE_ANSWER = "DELETE_ANSWER";

export const GET_INTERVIEW_INCENTIVE = "GET_INTERVIEW_INCENTIVE";
export const POST_INTERVIEW_INCENTIVE = "POST_INTERVIEW_INCENTIVE";
export const PUT_INTERVIEW_INCENTIVE = "PUT_INTERVIEW_INCENTIVE";
export const DELETE_INTERVIEW_INCENTIVE = "DELETE_INTERVIEW_INCENTIVE";

export const POST_API_ADMIN_LOGIN = "POST_API_ADMIN_LOGIN";
export const GET_API_COMPANIES = "GET_API_COMPANIES";
export const POST_API_SPOOF_TOKENS = "POST_API_SPOOF_TOKENS";
export const POST_API_COLLABORATOR_ADMIN = "POST_API_COLLABORATOR_ADMIN";
export const PUT_API_TRANSFER_OWNERSHIP_ADMIN =
  "PUT_API_TRANSFER_OWNERSHIP_ADMIN";
