import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import answer_footer from "./../../assets/images/answer_footer.png";
import communicationIcon from "./../../assets/images/goal_communication_panda.png";
import send from "./../../assets/images/sendYellow.png";
import white_mobile from "./../../assets/images/white_mobile.png";
import white_pc from "./../../assets/images/white_pc.png";
import black_mobile from "./../../assets/images/black_mobile.png";
import black_pc from "./../../assets/images/black_pc.png";
import { useDispatch } from "react-redux";
import Button from "../../components/basics/button";
import TypingComponent from "./typing";
import {
  getSlate,
  createProspectSession,
  getInterview,
  startTestDrive,
  advanceTestDrive,
  postTranscript,
  updateTranscript,
} from "../../action/api";
import { globalColor } from "../../assets/variable/global";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getCookie } from "../../config/common";
import NotFoundErrorModal from "../../components/modals/notFoundError";
import ServerErrorModal from "../../components/modals/serverError";

export default function AnswerText(props) {
  const chatBodyRef = useRef(null);
  const { id } = useParams();
  const location = useLocation();
  const [originalArr, setOriginalArr] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [slate, setSlate] = useState(
    location.state && location.state.slate ? location.state.slate : null
  );
  const test =
    location.state && location.state.test ? location.state.test : false;
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const [context, setContext] = useState("");
  const [mainArr, setMainArr] = useState([]);
  const [curArr, setCurArr] = useState("");
  const [isDesktop, setIsDesktop] = useState(true);
  const [typing, setTyping] = useState(false);
  const [transcript, setTranscript] = useState({ headline: "" });
  const [doneFlag, setDoneFlag] = useState(false);
  const [interview, setInterview] = useState(
    location.state && location.state.interview ? location.state.interview : {}
  );
  const [apiStatus, setApiStatus] = React.useState(200);
  const [curFlag, setCurFlag] = useState(false);
  const getSlateData = () => {
    if (!slate) {
      const slateID = localStorage.getItem("slateId");

      if (slateID && getCookie("access_token")) {
        getSlate(slateID)(dispatch)
          .then((res) => {
            if (res && res.status) {
              setApiStatus(res.status);
            } else {
              const { payloads } = res;
              setSlate(payloads[0]);
              if (interview.headline === undefined) {
                getInterview(
                  payloads[0].id,
                  payloads[0].interview_ids[0]
                )(dispatch).then((res) => {
                  if (res && res.status) {
                    setApiStatus(res.status);
                  } else {
                    const { payloads } = res;
                    setInterview(payloads[0]);
                  }
                });
              }
            }
          })
          .catch((err) => {
            console.log("getSlate error", err);
          });
      }
    } else {
      if (interview && interview.headline === undefined) {
        getInterview(
          slate.id,
          slate.interview_ids[0]
        )(dispatch).then((res) => {
          if (res && res.status) {
            setApiStatus(res.status);
          } else {
            const { payloads } = res;
            setInterview(payloads[0]);
          }
        });
      } else {
      }
    }
  };
  useEffect(() => {
    getSlateData();
  }, []);
  const handleToggle = () => {
    setIsDesktop(!isDesktop);
  };
  useEffect(() => {
    if (typing && text !== "") {
      checkScroll();
    }
  }, [typing]);
  function checkQuestionEmpty(arr, index) {
    if (
      arr[index].is_follow_up &&
      arr[index].question_text === "" &&
      arr[index].context !== ""
    )
      return 1;
    if (
      arr[index].is_follow_up &&
      arr[index].question_text !== "" &&
      arr[index].context !== ""
    )
      return 2;
    return -1;
  }
  function checkContextQuizArr(arr, flag, curText) {
    let tempLength = arr.length;
    if (tempLength === 0) return -2;
    const tempContext = arr[tempLength - 1].context
      ? arr[tempLength - 1].context
      : "";
    const tempQuestion = arr[tempLength - 1].question_text
      ? arr[tempLength - 1].question_text
      : "";
    let tempText =
      tempContext === "" ? tempQuestion : tempContext + " " + tempQuestion;
    let isFollowUp = arr[tempLength - 1].is_follow_up;
    if (tempLength > 0 && !flag && curText !== tempText) {
      if (tempQuestion === "" && tempContext !== "" && isFollowUp) return 0;
      else if (tempQuestion !== "" && tempContext !== "" && isFollowUp)
        return 1;
      else return 2;
    } else if (flag) return -1;
    return -2;
  }
  useEffect(() => {
    checkScroll();
    let condition = checkContextQuizArr(mainArr, doneFlag, curArr);
    if (condition === 0) {
      setCurArr(mainArr[mainArr.length - 1].context);
      setCurFlag(true);
      setTyping(true);
    } else if (condition === 1) {
      setCurArr(
        getQuiz(
          mainArr[mainArr.length - 1].question_text,
          mainArr[mainArr.length - 1].context
        )
      );
    } else if (condition === 2) {
      setCurArr(
        mainArr[mainArr.length - 1].question_text || `Q` + mainArr.length
      );
    } else {
    }
    if (condition > -2) {
      setCurFlag(true);
      setTyping(true);
      checkScroll();
    }
  }, [mainArr]);
  const getData = (code) => {
    if (test) {
      startTestDrive(code)(dispatch)
        .then((res) => {
          if (res && res.status) {
            setApiStatus(res.status);
          } else {
            const { payloads } = res;
            setMainArr([payloads[0].transcript[0]]);
            setOriginalArr([...payloads[0].transcript]);
            setTranscript(payloads[0]);
            setTyping(true);
            setCurrentOrder(0);
          }
        })
        .catch((err) => console.log("startTestDrive", err));
    } else {
      postTranscript(
        code,
        {}
      )(dispatch)
        .then((res) => {
          if (res && res.status) {
            if (getCookie(`prospect_${id}`)) setApiStatus(res.status);
            else {
              createProspectSession(id)(dispatch)
                .then((res) => {
                  window.location.reload();
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
          } else if (res && res.payloads) {
            const { payloads } = res;
            setOriginalArr([...payloads[0].transcript]);
            setTranscript(payloads[0]);
            let tempPos = 0;
            const tempPosArr = [];
            for (let i = 0; i < payloads[0].transcript.length; i++) {
              tempPosArr.push(payloads[0].transcript[i]);
              tempPos = payloads[0].transcript[i].order;
              if (
                !payloads[0].transcript[i].answer_text ||
                payloads[0].transcript[i].answer_text === ""
              )
                break;
            }
            if (
              payloads[0].transcript[payloads[0].transcript.length - 1]
                .answer_text &&
              tempPos >=
                payloads[0].transcript[payloads[0].transcript.length - 1].order
            )
              setDoneFlag(true);
            setCurrentOrder(tempPos);
            setMainArr([...tempPosArr]);
            setTyping(true);
            checkScroll();
          } else {
            createProspectSession(id)(dispatch)
              .then((res) => {
                window.location.reload();
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        })
        .catch((err) => console.log("getTransaction", err));
    }
  };
  useEffect(() => {
    if (doneFlag) checkScroll();
  }, [doneFlag]);
  const updateData = (obj) => {
    if (test) {
      const tempA = [...mainArr];
      if (!doneFlag) tempA[tempA.length - 1].answer_text = text;
      setText("");
      setCurFlag(false);

      setMainArr([...tempA]);
      checkScroll();
      advanceTestDrive(id, { ...obj })(dispatch)
        .then((res) => {
          {
            if (res && res.status) {
              setApiStatus(res.status);
            } else {
              const tempMainArr = [...mainArr];
              const { payloads } = res;
              const leng = payloads[0].transcript.length - 1;
              if (
                payloads[0].transcript[leng].question_text !== "" &&
                currentOrder !== payloads[0].transcript[leng].order
              ) {
                setCurrentOrder(payloads[0].transcript[leng].order);
                setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
              } else if (
                payloads[0].transcript.length === 2 &&
                checkQuestionEmpty(payloads[0].transcript, leng) === 1 &&
                currentOrder !== payloads[0].transcript[leng].order
              ) {
                setCurrentOrder(payloads[0].transcript[leng].order);
                setContext(payloads[0].transcript[leng].context);
                setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
              } else if (
                payloads[0].transcript.length === 2 &&
                checkQuestionEmpty(payloads[0].transcript, leng) === 2 &&
                currentOrder !== payloads[0].transcript[leng].order
              ) {
                setCurrentOrder(payloads[0].transcript[leng].order);
                setContext(
                  payloads[0].transcript[leng].context +
                    " " +
                    payloads[0].transcript[leng].question_text
                );
                setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
              } else {
                const tempOrder =
                  Math.floor(payloads[0].transcript[leng].order) + 1;
                if (tempOrder < originalArr.length) {
                  setCurrentOrder(tempOrder);
                  tempMainArr.push(originalArr[tempOrder]);
                  setMainArr([...tempMainArr]);
                } else {
                  setDoneFlag(true);
                  setTyping(true);
                  checkScroll();
                }
              }
            }
          }
        })
        .catch((err) => console.log("updateTranscript", err));
    } else {
      const tempA = [...mainArr];
      if (!doneFlag) tempA[tempA.length - 1].answer_text = text;
      setText("");
      setCurFlag(false);
      setMainArr([...tempA]);
      checkScroll();
      updateTranscript(id, transcript.id, {
        is_complete: true,
        transcript: obj.transcript,
        transcript_id: transcript.id,
      })(dispatch)
        .then((res) => {
          if (res && res.status) {
            setApiStatus(res.status);
          } else {
            const tempMainArr = [...mainArr];
            const { payloads } = res;
            let leng = payloads[0].transcript.findIndex(
              (item) =>
                currentOrder < item.order && item.order < currentOrder + 1
            );
            leng =
              leng === -1
                ? payloads[0].transcript.findIndex(
                    (item) => item.order === currentOrder
                  )
                : leng;
            if (
              currentOrder !== payloads[0].transcript[leng].order &&
              checkQuestionEmpty(payloads[0].transcript, leng) === 1
            ) {
              const tempQuestionText =
                payloads[0].transcript[leng].context +
                "? " +
                payloads[0].transcript[leng].question_text;
              const obj1 = {
                transcript: [
                  {
                    question_id: payloads[0].transcript[leng].question_id,
                    question_text: tempQuestionText,
                    questioned_at: formatDate(),
                    answer_id: payloads[0].transcript[leng].answer_id,
                    answered_at: formatDate(),
                    answer_text: "",
                    is_follow_up: true,
                    order: payloads[0].transcript[leng].order,
                    context: payloads[0].transcript[leng].context,
                  },
                ],
              };
              updateTranscript(id, transcript.id, {
                is_complete: true,
                transcript: obj1.transcript,
                transcript_id: transcript.id,
              })(dispatch).then((res1) => {
                setCurrentOrder(payloads[0].transcript[leng].order);
                setContext(obj1.transcript[0].question_text);
                setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
              });
            } else if (
              payloads[0].transcript[leng].question_text !== "" &&
              currentOrder !== payloads[0].transcript[leng].order
            ) {
              setCurrentOrder(payloads[0].transcript[leng].order);
              if (payloads[0].transcript[leng].context !== "") {
                setContext(payloads[0].transcript[leng].context);
                setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
              } else {
                setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
              }
            } else if (
              payloads[0].transcript.length >= 2 &&
              checkQuestionEmpty(payloads[0].transcript, leng) === 1 &&
              currentOrder !== payloads[0].transcript[leng].order
            ) {
              setCurrentOrder(payloads[0].transcript[leng].order);
              setContext(payloads[0].transcript[leng].context);
              setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
            } else if (
              payloads[0].transcript.length >= 2 &&
              checkQuestionEmpty(payloads[0].transcript, leng) === 2 &&
              currentOrder !== payloads[0].transcript[leng].order
            ) {
              setCurrentOrder(payloads[0].transcript[leng].order);
              setContext(
                payloads[0].transcript[leng].context +
                  " " +
                  payloads[0].transcript[leng].question_text
              );
              setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
            } else {
              const tempOrder =
                Math.floor(payloads[0].transcript[leng].order) + 1;

              if (tempOrder <= originalArr[originalArr.length - 1].order) {
                setCurrentOrder(tempOrder);
                tempMainArr.push(originalArr[tempOrder]);
                setMainArr([...tempMainArr]);
              } else {
                setDoneFlag(true);
                setTyping(true);
              }
            }
          }
        })
        .catch((err) => console.log("updateTranscript", err));
    }
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && text !== "") {
        event.preventDefault();
        sendFunc();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });
  function formatDate() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const sendFunc = () => {
    if (text != "" && !doneFlag) {
      const tempQT =
        mainArr[mainArr.length - 1].context !== "" &&
        mainArr[mainArr.length - 1].context &&
        mainArr[mainArr.length - 1].is_follow_up
          ? mainArr[mainArr.length - 1].context
          : "";

      const obj1 = {
        transcript: [
          {
            question_text:
              tempQT + " " + mainArr[mainArr.length - 1].question_text,
            questioned_at:
              mainArr[mainArr.length - 1].questioned_at || formatDate(),
            answered_at: formatDate(),
            answer_text: text,
            is_follow_up: mainArr[mainArr.length - 1].is_follow_up,
            order: mainArr[mainArr.length - 1].order,
          },
        ],
      };
      if (!test) {
        obj1.transcript[0].question_id =
          mainArr[mainArr.length - 1].question_id;
        if (mainArr[mainArr.length - 1].answer_id)
          obj1.transcript[0].answer_id = mainArr[mainArr.length - 1].answer_id;
      }
      updateData(obj1);
    } else if (doneFlag) {
      setTyping(false);
      const tempMainArr = [];
      const tempObj = { ...mainArr[mainArr.length - 1] };
      tempObj.answer_text =
        tempObj.answer_text === undefined
          ? text
          : tempObj.answer_text + " " + text;
      if (!test) {
        tempObj.question_id = mainArr[mainArr.length - 1].question_id;
        if (tempObj.answer_id === "") delete tempObj.answer_id;
        if (tempObj.answered_at === "") tempObj.answered_at = formatDate();
        if (tempObj.questioned_at === "") tempObj.questioned_at = formatDate();
      }
      if (tempObj.context !== "" && tempObj.is_follow_up) {
        tempObj.question_text = tempObj.context + " " + tempObj.question_text;
      }
      const obj1 = {
        transcript: [tempObj],
      };
      updateData(obj1);
      checkScroll();
    }
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };
  const checkScroll = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.style.scrollBehavior = "smooth";
      chatBodyRef.current.style.transition = "scroll 100ms ease-in";
      chatBodyRef.current.scrollIntoView({ block: "end" });
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };
  const textareaRef = useRef(null);
  const [lineCount, setLineCount] = useState(1);
  useEffect(() => {
    if (textareaRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(textareaRef.current).lineHeight
      );
      const currentLineCount = Math.floor(
        textareaRef.current.scrollHeight / lineHeight
      );
      if (currentLineCount !== lineCount) {
        setLineCount(currentLineCount);
      }
      const maxHeight = lineHeight * 2.5;
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, maxHeight)}px`;
      textareaRef.current.style.overflowY = "hidden";
    }
  }, [text, lineCount]);
  const handleChange = (e) => {
    setText(e.target.value);
  };
  useEffect(() => {
    if (
      !test &&
      (getCookie(`prospect_${id}`) === null ||
        getCookie(`prospect_${id}`) === "")
    ) {
      createProspectSession(id)(dispatch)
        .then((res) => {
          if (res && res.status) {
            setApiStatus(res.status);
          } else {
            setTimeout(() => {
              getData(id);
            }, 1000);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
      setTimeout(() => {
        getData(id);
      }, 1000);
    } else {
      getData(id);
    }
  }, [id]);
  function getQuiz(a, b) {
    if (a.includes(b)) return a;
    return b + " " + a;
  }

  return (
    <AnswerTextWrapper>
      {test && (
        <NavBar>
          <h3 className="nav-item left">
            Test Drive | <span> {slate.name}</span>
          </h3>
          <div className="nav-item">
            <div className="toggle-button">
              <button
                className={`toggle-option ${isDesktop ? "active" : ""}`}
                onClick={() => setIsDesktop(true)}
              >
                <img src={isDesktop ? white_pc : black_pc} alt="pc" />
              </button>
              <button
                className={`toggle-option ${!isDesktop ? "active" : ""}`}
                onClick={() => setIsDesktop(false)}
              >
                <img src={!isDesktop ? white_mobile : black_mobile} alt="pc" />
              </button>
            </div>
          </div>
          <div className="nav-item right">
            <Button
              title="Close Test Drive "
              icon="closeIcon"
              onClickBtn={() => {
                if (slate)
                  navigate("/creator-dashboard/smart-interviews/" + slate.id, {
                    state: slate,
                  });
              }}
            />
          </div>
        </NavBar>
      )}
      {isDesktop && (
        <>
          <AnswerHeader>
            {transcript.logo_url && (
              <img src={transcript.logo_url} alt="Logo" />
            )}
          </AnswerHeader>
          <AnswerBody>
            <h2> {interview.headline || transcript.headline}</h2>
            <div className="chatBody">
              <div className="chatWrapper" ref={chatBodyRef}>
                {mainArr.length > 0 &&
                  mainArr.map(
                    (quiz, qIndex) =>
                      quiz &&
                      ((quiz.order < currentOrder && !quiz.answer_text) ||
                        (quiz.order <= currentOrder && quiz.answer_text) ||
                        (quiz.order === currentOrder && !curFlag) ||
                        (quiz.order === currentOrder &&
                          doneFlag &&
                          curArr.includes("Thanks for your feedback."))) && (
                        <div key={"main-aui-" + qIndex}>
                          {
                            <div
                              className="oneChat left-chat"
                              key={"chat-" + qIndex}
                            >
                              <img src={communicationIcon} alt="chat-panda" />
                              <p>
                                {quiz.question_text === ""
                                  ? quiz.context
                                  : quiz.context === ""
                                    ? quiz.question_text || `Q` + (qIndex + 1)
                                    : getQuiz(quiz.question_text, quiz.context)}
                              </p>
                            </div>
                          }
                          {quiz.answer_text !== "" && quiz.answer_text && (
                            <div
                              className="oneChat right-chat"
                              key={"chat-" + qIndex + "-answer"}
                            >
                              <p>{quiz.answer_text}</p>
                            </div>
                          )}
                        </div>
                      )
                  )}
                {!typing && curFlag && curArr !== "" && (
                  <div className="oneChat left-chat">
                    <img src={communicationIcon} alt="chat-panda" />
                    <p>{curArr}</p>
                  </div>
                )}
                {typing === true && !doneFlag && (
                  <div className={`typing oneChat left-chat`}>
                    {text === "" && (
                      <img src={communicationIcon} alt="chat-panda" />
                    )}
                    <TypingComponent
                      text={curArr || `Q` + mainArr.length}
                      color={"black"}
                      onTypingStart={() => {
                        checkScroll();
                      }}
                      onTypingFinish={() => {
                        checkScroll();
                        setCurFlag(true);
                        setTyping(false);
                        if (textareaRef.current) {
                          textareaRef.current.focus();
                        }
                        if (
                          context !== "" &&
                          mainArr[mainArr.length - 1].question_text === ""
                        ) {
                          setCurFlag(false);
                          setCurArr("");
                          setTimeout(() => {
                            const tempOrder = Math.floor(currentOrder) + 1;
                            const index = originalArr.findIndex(
                              (item) => item.order === tempOrder
                            );
                            if (originalArr[index]) {
                              setCurrentOrder(tempOrder);
                              setMainArr([...mainArr, originalArr[index]]);
                            } else {
                              setDoneFlag(true);
                            }
                            setTyping(true);
                            checkScroll();
                            setContext("");
                          }, 500);
                        }
                      }}
                    />
                  </div>
                )}
                {typing === true && doneFlag && (
                  <div className={`typing oneChat left-chat`}>
                    {text === "" && (
                      <img src={communicationIcon} alt="chat-panda" />
                    )}
                    <TypingComponent
                      text={
                        "Thanks for your feedback. To complete the smart interview please click Done."
                      }
                      color={"black"}
                      onTypingStart={() => {
                        checkScroll();
                      }}
                      onTypingFinish={() => {
                        checkScroll();
                        setTimeout(() => {
                          setCurArr(
                            "Thanks for your feedback. To complete the smart interview please click Done."
                          );
                          setCurFlag(true);
                          setTyping(false);
                          if (textareaRef.current) {
                            textareaRef.current.focus();
                          }
                          checkScroll();
                        }, 500);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className={`inputWrapper ${doneFlag ? "doneW" : ""}`}>
                {!doneFlag && <div className="form-input">
                  <textarea
                    ref={textareaRef}
                    value={text}
                    onChange={handleChange}
                    rows="1"
                    style={{
                      width: "100%",
                      resize: "none",
                    }}
                    placeholder="Type here..."
                  />
                  <img
                    style={{ width: `20px` }}
                    src={send}
                    onClick={() => {
                      if (text !== "") sendFunc();
                    }}
                  />
                </div>}
                {doneFlag && (
                  <div
                    className="done"
                    onClick={() => {
                      navigate("/smartinterviews/thankyou/" + id, {
                        state: {
                          interview: transcript,
                          test: test,
                          slate: slate,
                          url_code: id,
                          logo: transcript.logo_url || "",
                        },
                      });
                    }}
                  >
                    Done
                  </div>
                )}
              </div>
            </div>
            <div className="answerFooter">
              <img src={answer_footer} alt="footer" />
            </div>
          </AnswerBody>
        </>
      )}
      {!isDesktop && (
        <div className="mobile-wrapper">
          <AnswerMobileHeader>
            {transcript.logo_url && (
              <img src={transcript.logo_url} alt="Logo" />
            )}
          </AnswerMobileHeader>
          <AnswerMobileBody>
            <h2> {interview.headline}</h2>
            <div className="chatBody">
              <div className="chatWrapper" ref={chatBodyRef}>
                {mainArr.map(
                  (quiz, qIndex) =>
                    quiz.answer_text &&
                    quiz.answer_text !== "" && (
                      <div key={"main-aui-" + qIndex}>
                        <div
                          className="oneChat left-chat"
                          key={"chat-" + qIndex}
                        >
                          <img src={communicationIcon} alt="chat-panda" />
                          <p>{quiz.question_text || `Q` + (qIndex + 1)}</p>
                        </div>
                        {quiz.answer_text !== "" && (
                          <div
                            className="oneChat right-chat"
                            key={"chat-" + qIndex + "-answer"}
                          >
                            <p>{quiz.answer_text}</p>
                          </div>
                        )}
                      </div>
                    )
                )}
                {!typing && curFlag && curArr !== "" && (
                  <div className="oneChat left-chat">
                    <img src={communicationIcon} alt="chat-panda" />
                    <p>{curArr}</p>
                  </div>
                )}
                {typing === true && !doneFlag && (
                  <div className={`typing oneChat left-chat`}>
                    {text === "" && (
                      <img src={communicationIcon} alt="chat-panda" />
                    )}
                    <TypingComponent
                      text={curArr || `Q` + mainArr.length}
                      onTypingStart={() => {
                        checkScroll();
                      }}
                      color={"black"}
                      onTypingFinish={() => {
                        checkScroll();
                        setCurFlag(true);
                        if (context !== "") {
                          if (
                            mainArr[mainArr.length - 1].question_text !== "" &&
                            mainArr[mainArr.length - 1].is_follow_up
                          )
                            setCurArr(
                              mainArr[mainArr.length - 1].question_text ||
                                `Q` + mainArr.length
                            );
                          setTyping(true);
                          checkScroll();
                          setContext("");
                        } else {
                          setTyping(false);
                          if (textareaRef.current) {
                            textareaRef.current.focus();
                          }
                        }
                      }}
                    />
                  </div>
                )}
                {typing === true && doneFlag && (
                  <div className={`typing oneChat left-chat`}>
                    {text === "" && (
                      <img src={communicationIcon} alt="chat-panda" />
                    )}
                    <TypingComponent
                      text={
                        "Thanks for your feedback. To complete the smart interview please click Done."
                      }
                      color={"black"}
                      onTypingStart={() => {
                        checkScroll();
                      }}
                      onTypingFinish={() => {
                        checkScroll();
                        setTimeout(() => {
                          setCurArr(
                            "Thanks for your feedback. To complete the smart interview please click Done."
                          );
                          setCurFlag(true);
                          setTyping(false);
                          if (textareaRef.current) {
                            textareaRef.current.focus();
                          }
                          checkScroll();
                        }, 500);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className={`inputWrapper ${doneFlag ? "doneW" : ""}`}>
                {!doneFlag && <div className="form-input">
                  <textarea
                    ref={textareaRef}
                    value={text}
                    onChange={handleChange}
                    rows="1"
                    style={{
                      width: "100%",
                      resize: "none",
                    }}
                    placeholder="Type here..."
                  />
                  <img
                    style={{ width: `20px` }}
                    src={send}
                    onClick={() => {
                      if (text !== "") sendFunc();
                    }}
                  />
                </div>}
                {doneFlag && (
                  <div
                    className="done"
                    onClick={() => {
                      navigate("/smartinterviews/thankyou/" + id, {
                        state: {
                          interview: transcript,
                          test: test,
                          slate: slate,
                          url_code: id,
                          logo: transcript.logo_url || "",
                        },
                      });
                    }}
                  >
                    Done
                  </div>
                )}
              </div>
            </div>
            <div className="answerFooter">
              <img src={answer_footer} alt="footer" />
              <h4>Create a smart interview for your business!</h4>
            </div>
          </AnswerMobileBody>
        </div>
      )}
      {apiStatus === 404 && (
        <NotFoundErrorModal
          closeModal={() => {
            setApiStatus(200);
          }}
          title=""
          description=""
          saveModal={(obj) => {}}
        />
      )}
      {apiStatus === 500 && (
        <ServerErrorModal
          closeModal={() => {
            setApiStatus(200);
          }}
          title=""
          description=""
          saveModal={(obj) => {}}
        />
      )}
    </AnswerTextWrapper>
  );
}

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${globalColor.gray_500};
  padding: 17px 32px;
  .nav-item {
    flex: 1;
    display: flex;
    justify-content: center;
    &.left {
      justify-content: start;
    }
    &.right {
      justify-content: end;
      align-items: center;
    }
  }
  h3 {
    color: ${globalColor.gray_600};
    margin: 0px;
    font-size: 30px;
    line-height: 38px;
    font-family: "Figtree";
    span {
      padding-left: 10px;
      font-weight: bold;
    }
  }
  .toggle-button {
    display: flex;
    border: 1px solid ${globalColor.gray_300};
    border-radius: 8px;
    overflow: hidden;
  }
  .toggle-option {
    flex: 1;
    padding: 12px 17px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  .toggle-option.active {
    background-color: ${globalColor.primary_600};
    color: #fff;
  }
  .toggle-option:not(.active):hover {
    background-color: #e0e0e0;
  }
`;
const AnswerHeader = styled.div`
  background-color: #dfe4ed;
  display: flex;
  padding: 12px;
  img {
    height: 60px;
  }
  justify-content: center;
`;
const AnswerMobileHeader = styled.div`
  background-color: #dfe4ed;
  display: flex;
  padding: 6px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  img {
    height: 40px;
  }
  justify-content: center;
`;
const AnswerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 165px);
  padding: 60px;
  @media (max-width: 600px) {
    padding: 10px;
  }
  .done {
    cursor: pointer;
    position: absolute;
    right: 15px;
    bottom: 26px;
    border-radius: 12px;
    /* border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; */
    color: white;
    background-color: ${globalColor.primary_600};
    padding: 12px 16px;
  }
  h2 {
    max-width: 600px;
    text-align: center;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    font-family: "Figtree";
    margin: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  img.coupon {
    padding: 40px 0px;
    max-width: 400px;
  }
  .chatBody {
    position: relative;
    border-radius: 20px;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: white;
    flex-grow: 1;
    min-height: 400px;
    .chatWrapper {
      position: absolute;
      width: 100%;
      left: 0px;
      padding: 20px;
      top: 0px;
      height: calc(100% - 72px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px; /* Remove vertical scrollbar */
        height: 0px; /* Remove horizontal scrollbar */
      }

      /* Optionally, hide scrollbar track and thumb */
      &::-webkit-scrollbar-track {
        background: transparent; /* Make the track transparent */
      }

      &::-webkit-scrollbar-thumb {
        background: transparent; /* Make the thumb transparent */
      }
      .oneChat {
        display: flex;
        overflow: auto;
        gap: 16px;
        margin-bottom: 12px;
        img {
          width: 50px;
          height: 47px;
          margin-top: auto;
        }
        p,
        .typing-p {
          margin: 0px;
          background-color: ${globalColor.gray_50};
          padding: 12px 16px;
          font-size: 16px;
          line-height: 24px;
          color: black;
          max-width: 306px;
          word-break: break-word;
          border-radius: 12px;
        }
        &.left-chat {
          p,
          .typing-p {
            border-bottom-left-radius: 0px;
          }
        }
        &.right-chat {
          p,
          .typing-p {
            border-bottom-right-radius: 0px;
            margin-left: auto;
            background: #1693c7;
            color: white;
          }
        }
      }
    }
  }
  .form-input {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 20px;
    textarea {
      width: 100%;
      padding: 12px 24px;
      border: 1px solid #dfe4ed;
      border-radius: 100px;
      padding-right: 40px;
    }
    img {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
  .inputWrapper.doneW .form-input {
    left: 10px;
    width: calc(100% - 110px);
  }
  @media (max-width: 600px) {
    .inputWrapper .form-input {
      left: 10px;
      width: calc(100% - 20px);
    }
    .inputWrapper.doneW .form-input {
      left: 10px;
      width: calc(100% - 110px);
    }
  }
  .answerFooter {
    img {
      padding-bottom: 40px;
    }
    @media (max-width: 500px) {
      display: none !important;
    }
  }
`;
const AnswerMobileBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 100px);
  padding: 12px 20px;
  .done {
    cursor: pointer;
    position: absolute;
    right: 15px;
    bottom: 26px;
    border-radius: 12px;
    color: white;
    background-color: ${globalColor.primary_600};
    padding: 10px 16px;
  }
  h2 {
    max-width: 600px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    font-family: "Figtree";
    margin: 0px;
    padding: 0px 30px;
  }
  img.coupon {
    padding: 12px 0px;
    max-width: 285px;
  }
  .chatBody {
    position: relative;
    border-radius: 20px;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: white;
    flex-grow: 1;
    .chatWrapper {
      position: absolute;
      width: 100%;
      left: 0px;
      padding: 20px;
      top: 0px;
      height: calc(100% - 72px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px; /* Remove vertical scrollbar */
        height: 0px; /* Remove horizontal scrollbar */
      }

      /* Optionally, hide scrollbar track and thumb */
      &::-webkit-scrollbar-track {
        background: transparent; /* Make the track transparent */
      }

      &::-webkit-scrollbar-thumb {
        background: transparent; /* Make the thumb transparent */
      }
      .oneChat {
        display: flex;
        gap: 16px;
        margin-bottom: 12px;
        img {
          width: 50px;
          height: 47px;
          margin-top: auto;
        }
        p,
        .typing-p {
          margin: 0px;
          background-color: ${globalColor.gray_50};
          padding: 8px 12px;
          font-size: 14px;
          line-height: 20px;
          color: black;
          max-width: 190px;
          border-radius: 12px;
          border-bottom-right-radius: 0px;
        }
        &.right-chat {
          p {
            border-bottom-right-radius: 0px;
            margin-left: auto;
            background: #1693c7;
            color: white;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
  .form-input {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 20px;
    textarea {
      width: 100%;
      padding: 12px 24px;
      border: 1px solid #dfe4ed;
      border-radius: 100px;
      padding-right: 40px;
      font-size: 14px;
      line-height: 20px;
    }
    img {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
  .inputWrapper.doneW .form-input {
    left: 10px;
    width: calc(100% - 110px);
  }
  .answerFooter {
    img {
      padding-bottom: 8px;
      padding-top: 24px;
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
    h4 {
      color: ${globalColor.gray_500};
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      font-family: Figtree;
    }
    @media (max-width: 500px) {
      display: none !important;
    }
  }
`;

const AnswerTextWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f7f9fb;
  .mobile-wrapper {
    display: flex;
    justify-content: center;
    width: 375px;
    box-shadow: 4px 4px 30px; 0 rga(52,64,84, 0.15);
    border-radius: 12px;
    border: 0px;
    background-color: #f7f9fb;
    margin: 50px auto;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    height: calc(100vh - 183px);
  }
  form {
    width: 100%;
  }
  form .form-control.form-validation {
    border-color: red;
    margin-bottom: 3px;
  }
  form .form-group {
    label {
      color: #344054;
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      margin-bottom: 6px;
    }
    input,
    textarea {
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: #667085;
      font-family: Figtree;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-bottom: 16px;
    }
  }
`;
