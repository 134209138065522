import http from "./config/http-common";
import {
  getCookie,
  setCookie,
  handleBeforeUnload,
  deleteCookie,
} from "./config/common";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// function used to check the validity of the token using jwt library
const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const { exp } = jwtDecode(token);
    return Date.now() < exp * 1000;
  } catch (error) {
    return false;
  }
};

// uses axios library interceptor.request.use() method to intercept all requests and to perform any updates, error handling or logic checks before sending to endpoints
// added condition to exclude '/url/' and '/transctipt/' endpoints and include refresh token checking and access token refreshing if required
http.interceptors.request.use(
  async (config) => {
    if (window.location.href.includes("/smartinterviews/")) return config;

    let token = getCookie("access_token", config.url.includes("/admin/"));

    //  access token is expired
    if (!isTokenValid(token)) {
      const refreshToken = getCookie(
        "refresh_token",
        config.url.includes("/admin/")
      );

      //  refresh token is expired
      if (!isTokenValid(refreshToken)) {
        // excluding auth/login route to allow create account redirect to login flows to process without checking for refresh token
        if (
          !config.url.includes("/auth/admin/login") &&
          !config.url.includes("/auth/login") &&
          !config.url.includes("/users/verification/") &&
          !config.url.includes("/users/external")
        ) {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          localStorage.clear();
          if (!window.location.href.includes("/mission-control/"))
            window.location.href = "/creator-dashboard/login";
          else window.location.href = "/mission-control/login";

          return Promise.reject("Tokens expired");
        }
      } else {
        const currentRefreshToken = getCookie(
          "refresh_token",
          config.url.includes("/admin/")
        );
        if (!currentRefreshToken) {
          if (!window.location.href.includes("/creator-dashboard/login"))
            window.location.href = "/creator-dashboard/login";
          throw new Error("No refresh token available.");
        }

        const refreshApi = axios.create();
        const refreshKID = "28B48444-F87E-487E-A504-61FB68A19149";
        const response = await refreshApi.post(
          `/v1/auth/refresh/${refreshKID}`,
          {},
          {
            headers: {
              Authorization: `Bearer ` + currentRefreshToken,
            },
          }
        );
        const email = JSON.parse(
          localStorage.getItem(
            !config.url.includes("/admin/") ? "user" : "admin_user"
          )
        ).email;

        setCookie(
          `access_${email}`,
          response.data.access_token,
          response.data.access_token_expires_at
        );

        const updatedToken = getCookie(
          "access_token",
          config.url.includes("/admin/")
        );

        if (updatedToken)
          config.headers["Authorization"] = `Bearer ${updatedToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// uses axios interceptor.response.use() function to intercept all responses to perform any updates, error handling, or logic checks before sending response to components
http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.status === 401 &&
      error.response.data.message === "not authenticated"
    ) {
      if (!window.location.href.includes("/mission-control/")) {
        deleteCookie("access_token");
        deleteCookie("refresh_token");
      } else {
        deleteCookie("access_token", true);
        deleteCookie("refresh_token", true);
      }
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);
