import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteAdminUser, transferOwnershipAdmin } from "../../action/api";
import { getRole } from "../../config/common";

const CollaboratorModalItem = (prop) => {
  const { collaborator, border, showOption, onClickOption, owner } = prop;
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 16,
        paddingBottom: 16,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: border ? 0 : 0.1,
        borderBottomColor: "#EAECF0",
        borderStyle: "solid",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ color: "black", fontSize: 18 }}>{collaborator.name}</div>
        <div style={{ color: "#475467", fontSize: 14 }}>
          {collaborator.email}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 16,
        }}
      >
        <div style={{ color: "#475467", fontSize: 16 }}>
          {collaborator.roles?.length > 0 && getRole(collaborator.roles)}
        </div>
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            className="optionmenu"
            style={{ padding: 10, cursor: "pointer" }}
            onClick={() => onClickOption(-1)}
          >
            <i className="fa fa-ellipsis-v"></i>
          </div>
          {/*  */}
          {showOption === true && (
            <div
              style={{
                position: "absolute",
                width: 200,
                backgroundColor: "white",
                padding: 12,
                boxShadow: "0px 1px 3px 0px #b7b7b7",
                borderRadius: 8,
                zIndex: 999,
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {collaborator.roles?.length > 0 &&
                !collaborator.roles.find(
                  (r) => r === "OWNER" || r === "SUPER_ADMIN"
                ) && (
                  <div
                    onClick={() => {
                      transferOwnershipAdmin(
                        collaborator.id,
                        owner.id
                      )(dispatch)
                        .then((res) => {
                          onClickOption(0, res);
                        })
                        .catch((err) => {
                          onClickOption(0);
                        });
                    }}
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    <i className="fa fa-refresh"></i>Transfer ownership
                  </div>
                )}
              <div
                onClick={() => {
                  deleteAdminUser(collaborator.id)(dispatch)
                    .then((res) => {
                      if (res && res.status) {
                        onClickOption(2); //  error handle
                      } else onClickOption(1);
                    })
                    .catch((err) => {
                      onClickOption(1);
                    });
                }}
                style={{
                  display: "flex",
                  cursor: "pointer",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <i className="fa fa-remove"></i>Remove access
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollaboratorModalItem;
