import { useState } from "react";

export default function useToken() {
  const getUserId = () => {
    const user = localStorage.getItem("user_id");
    return user;
  };
  const getOnboarded = () => {
    const user = localStorage.getItem("user_onboard");
    return user;
  };
  const [userId, setUserId] = useState(getUserId());
  const [is_onboarded, setOnboarded] = useState(getOnboarded());
  const saveToken = (user) => {
    localStorage.setItem("admin_user_id", user.id);
    localStorage.setItem("admin_user", JSON.stringify(user));
    localStorage.setItem("admin_user_onboard", user.is_onboarded);
    setUserId(user.id);
    setOnboarded(user.is_onboarded);
  };

  return {
    setToken: saveToken,
    userId,
    is_onboarded,
  };
}
