import React from "react";
import { useParams, useLocation } from "react-router-dom";
import ViewInterview from "./viewInterview";
import EditSlate from "./editSlate";

export default function EditInterview(props) {
  const { id } = useParams();
  const location = useLocation();

  return location === null || location.state === null ? <>{window.location.href = "/creator-dashboard/smart-interviews"}</> : location.state.completed ? (
    <ViewInterview
      slate={location.state.slate ? location.state.slate : location.state}
    />
  ) : (
    <EditSlate
      id={id}
      slate={location.state.slate ? location.state.slate : location.state}
      new_slate={location.state.new_slate}
    />
  );
}
