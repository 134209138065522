import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

export default function TypingComponent(props) {
  const { text, onTypingFinish, color, onTypingStart } = props;
  const [displayText, setDisplayText] = useState("");
  const [showCursor, setShowCursor] = useState(true);
  const [showCursorFlag, setShowCursorFlag] = useState(true);
  const containerRef = useRef(null);
  const textRef = useRef(null);
  useEffect(() => {
    let currentIndex = -1;
    let intervalSubId;
    if (!text || displayText === "") onTypingStart();
    const typeText = () => {
      if (currentIndex < text.length - 1) {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        currentIndex++;
      } else if (text !== "") {
        setShowCursorFlag(false);
        clearInterval(intervalSubId);
        onTypingFinish();
      }
    };
    const intervalId = setTimeout(function () {
      intervalSubId = setInterval(typeText, 10);
    }, 350); // Adjust the typing speed here (in milliseconds)
    return () => {
      clearInterval(intervalSubId);
      clearTimeout(intervalId);
    };
  }, [text]);

  useEffect(() => {
    if (showCursor) {
      const cursorIntervalId = setInterval(() => {
        setShowCursor((prevShowCursor) => !prevShowCursor);
      }, 350); // Adjust the cursor blink speed here (in milliseconds)
      return () => {
        clearInterval(cursorIntervalId);
      };
    }
  }, []);

  useEffect(() => {
    if (textRef.current !== null && containerRef.current !== null) {
      const containerWidth = textRef.current.offsetWidth;
      containerRef.current.style.width = `${containerWidth + 20}px`;
    }
  }, []);

  return (
    <div ref={containerRef} style={{ display: "inline" }} className="typing-p">
      <>
        {text !== "" ? (
          <TypeContent color={color}>
            {displayText}
            {displayText === "" ? (
              <div className="cursor-area">
                {showCursorFlag && showCursor && (
                  <span className="blinking-cursor">|</span>
                )}
              </div>
            ) : (
              showCursorFlag &&
              showCursor && <span className="blinking-cursor">|</span>
            )}
          </TypeContent>
        ) : (
          <TypeContent color={color}>
            <div className="cursor-area">
              {showCursorFlag && showCursor && (
                <span className="blinking-cursor">|</span>
              )}
            </div>
          </TypeContent>
        )}
      </>
    </div>
  );
}

const TypeContent = styled.div`
  display: inline;
  justify-content: center;
  padding-left: 0px;
  color: ${(props) => props.color};
  .cursor-area {
    width: 14px;
    height: 16px;
  }
`;
