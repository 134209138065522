import React from "react";
import styled from "styled-components";
import Button from "../components/basics/button";
import { globalColor, font_text_sm_Semibold } from "../assets/variable/global";
import image from "../assets/images/404_image.png";

export default function NotFound() {
  return (
    <NotFoundWrapper>
      <Content>
        <LeftSide>
          <p className="text1">404 Error</p>
          <p className="text2">Oops! Something went wrong...</p>
          <p className="text3">
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </p>
          <div style={{ display: "flex", padding: "48px 0" }}>
            {/* <Button
              title="Go back"
              outline
              icon="arrowLeft"
              width="initial"
              onClickBtn={() => {
                window.history.go(-1);
              }}
            /> */}
            <Button
              title="Take me home"
              width="initial"
              onClickBtn={() => {
                window.location.href = "/creator-dashboard";
              }}
            />
          </div>
          <p>
            <a href="https://perceptivepanda.com" style={font_text_sm_Semibold}>
              Visit our Support Page &nbsp;
              <i className="fas fa-arrow-right"></i>
            </a>
            <br />
            Our friendly team is here to help.
          </p>
        </LeftSide>
        <RightSide>
          <img src={image} alt=""></img>
        </RightSide>
      </Content>
    </NotFoundWrapper>
  );
}

const NotFoundWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`;
const Content = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  display: flex;
`;
const LeftSide = styled.div`
  width: 50%;
  position: relative;
  padding: 0 32px;
  display: inline;
  font-family: Figtree;
  p {
    margin-bottom: 0;
  }
  p.text1 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: ${globalColor.primary_600};
  }
  p.text2 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 600;
    color: ${globalColor.gray_900};
    margin-bottom: 24px;
  }
  p.text3 {
    font-size: 20px;
    line-height: 30px;
    color: ${globalColor.gray_600};
  }
`;
const RightSide = styled.div`
  width: 50%;
  position: relative;
  height: 100%;
  padding: 0 32px;
  img {
    display: block;
    max-width: 400px;
  }
`;
