import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Button from "../basics/button";
import InputTags from "../tags";
import link03 from "../../assets/images/link-03.png";
import CollaboratorModalItem from "./CollaboratorModalItem";
import { getRolePriority } from "./../../config/common";

export default function CollaboratorModal(props) {
  const {
    closeModal,
    data,
    onCollaboratorEdit,
    onAddCollaborator,
    errText,
    colEmail,
    onEmailChange,
  } = props;
  const [showOptionIndex, setShowOptionIndex] = useState(-1);
  const [flag, setFlag] = useState(errText !== "" ? 0 : -1);
  const [collaboratorList, setCollaboratorList] = useState(data.users);
  const inputRef = useRef(null);
  const [optionErrText, setOptionErrText] = useState("");
  const [errorIndex, setErrorIndex] = useState(-1);
  useEffect(() => {
    if (flag === 0) {
      if (errText !== "") setFlag(1);
      else onAddCollaborator(colEmail);
    }
    if (flag === -1)
      if (inputRef.current) {
        inputRef.current.focus(); // Set focus on the input element
      }
  }, [flag]);
  const onMouseDownHandler = (e) => {
    if (e.target.closest(".optionmenu") === null) {
      setShowOptionIndex(-1);
    }
  };
  useEffect(() => {
    document.addEventListener("click", onMouseDownHandler);
    return () => {
      document.removeEventListener("click", onMouseDownHandler);
    };
  }, []);

  return (
    <CollaboratorModalWrapper>
      <div
        className="modal fade show"
        tabIndex={-1}
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <form>
            <div className="modal-content">
              <div
                className="modal-header"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Owner & Collaborators
                </h5>
                <div onClick={closeModal} style={{ cursor: "pointer" }}>
                  <i className="fa fa-close"></i>
                </div>
              </div>
              <div className="modal-body">
                {collaboratorList
                  ?.sort((a, b) => {
                    if (getRolePriority(a.roles) === getRolePriority(b.roles))
                      return 0;
                    if (getRolePriority(a.roles) > getRolePriority(b.roles))
                      return 1;
                    return -1;
                  })
                  .map((collaborator, index) => {
                    return (
                      <div key={"collaborator" + index}>
                        <CollaboratorModalItem
                          onClickOption={(e, res = null) => {
                            if (e === 1) {
                              const tempList = [...collaboratorList];
                              tempList.splice(index, 1);
                              setCollaboratorList([...tempList]);
                              onCollaboratorEdit(e);
                            } else if (e === 0 && res !== null) {
                              const tempList = [...collaboratorList];
                              tempList.find(
                                (c) => c.id === res.payloads[0].id
                              ).roles = res.payloads[0].roles;
                              tempList.find(
                                (c) => c.id === res.payloads[1].id
                              ).roles = res.payloads[1].roles;
                              setCollaboratorList([...tempList]);
                              onCollaboratorEdit(e);
                            } else if (e === -1) {
                              setErrorIndex(-1);
                              setShowOptionIndex(index);
                            } else {
                              setErrorIndex(index);
                              setOptionErrText(
                                "You are not able to remove your own access."
                              );
                            }
                          }}
                          showOption={index === showOptionIndex}
                          collaborator={collaborator}
                          owner={collaboratorList.find((c) =>
                            c.roles.find(
                              (r) => r === "OWNER" || r === "SUPER_ADMIN"
                            )
                          )}
                          key={index}
                          border={index + 1 === data.users.length}
                        />
                        {optionErrText && index === errorIndex && (
                          <p className="required-html">{optionErrText}</p>
                        )}
                      </div>
                    );
                  })}
                <div
                  className="form-group"
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                  }}
                >
                  <label htmlFor="modal_to"> Add collaborators</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 12,
                      alignItems: "baseline",
                    }}
                  >
                    <input
                      ref={inputRef}
                      type={"text"}
                      className={`form-control ${flag === 1 && errText !== "" && "form-validation"}`}
                      id="modal_to"
                      placeholder="Collaborator's email address"
                      value={colEmail}
                      onChange={(e) => {
                        onEmailChange(e.target.value);
                      }}
                    />
                    <Button
                      title={"Invite"}
                      disable={!colEmail}
                      onClickBtn={() => {
                        setFlag(0);
                      }}
                    />
                  </div>
                  {flag === 1 && <p className="required-html">{errText}</p>}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </CollaboratorModalWrapper>
  );
}

const CollaboratorModalWrapper = styled.div`
  .modal.fade {
    background: none;
    .modal-dialog-centered {
      display: grid;
    }
    form .form-control.form-validation {
      border-color: red;
      margin-bottom: 3px;
    }
    form .form-group {
      label {
        color: #344054;
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 6px;
      }
      input,
      textarea {
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #667085;
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 16px;
      }
    }
    .modal-footer {
      display: block;
      h3 {
        color: var(--gray-900, #101828);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      p {
        color: var(--gray-600, #475467);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .modal-footer-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
    .button-copy {
      background: #1693c7;
      padding: 10px 16px;
      border-radius: 8px;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-family: Figtree;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      i {
        margin-right: 8px;
      }
      &.email {
        background: white;
        color: #344054;
        border: 1px solid #d0d5dd;
        margin-right: 10px;
      }
    }
  }
  p.required-html {
    color: red !important;
    font-size: 12px;
  }
`;
