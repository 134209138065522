const rolePriority = {
  SUPER_ADMIN: 0,
  ADMIN: 1,
  OWNER: 2,
  CREATOR: 3,
  COLLABORATOR: 4,
};

export function getErrorMessage(errorMsg) {
  // console.log(errorMsg);
  if (errorMsg === undefined) {
    return "Something went wrong. Please try you action again.";
  } else {
    if (errorMsg.message === "duplicated key not allowed")
      return "An account with this email address or company name already exists. Please use a different email address or company name to create a new account.";
    else if (errorMsg.message === "invalid argument passed")
      return errorMsg.fields[0].error;
    else if (errorMsg.text !== undefined) {
      if (errorMsg.text.includes("'required' tag"))
        return "A required field is missing data. Please add the the required data before clicking Save.";
      else if (errorMsg.text.includes("'min' tag"))
        return "Answers are required to be four or more characters long.";
    } else if (errorMsg.cta_standard_text !== undefined) {
      if (errorMsg.cta_standard_text.includes("'required' tag"))
        return "Standard call to action field is missing data. Please add the the required data before clicking Save.";
      else if (errorMsg.cta_standard_text.includes("'min' tag"))
        return "Standard call to action is required to be four or more characters long.";
    } else if (errorMsg.fitness_criteria_id !== undefined) {
      return "Fitness Criteria field is missing data. Please add the the required data before clicking Save.";
    } else if (errorMsg.fitness_criteria_id !== undefined) {
      return "Fitness Criteria field is missing data. Please add the the required data before clicking Save.";
    } else if (errorMsg.experience !== undefined) {
      return "Interview experience field is missing data. Please add the the required data before clicking Save.";
    } else if (errorMsg.style !== undefined) {
      return "Style field is missing data. Please add the the required data before clicking Save.";
    } else if (errorMsg.cta_secondary_text !== undefined) {
      if (errorMsg.cta_secondary_text.includes("'required' tag"))
        return "CTA Secondary text field is missing data. Please add the the required data before clicking Save.";
      else if (errorMsg.cta_secondary_text.includes("'min' tag"))
        return "CTA Secondary text field is required to be four or more characters long.";
    } else if (errorMsg.interview_script_incentive_text !== undefined) {
      if (errorMsg.interview_script_incentive_text.includes("'required' tag"))
        return "Interview script incetive text field is missing data. Please add the the required data before clicking Save.";
      else if (errorMsg.interview_script_incentive_text.includes("'min' tag"))
        return "Interview script incetive text field is required to be four or more characters long.";
    } else if (errorMsg.interview_script_completed_text !== undefined) {
      if (errorMsg.interview_script_completed_text.includes("'required' tag"))
        return "Interview script completed text field is missing data. Please add the the required data before clicking Save.";
      else if (errorMsg.interview_script_completed_text.includes("'min' tag"))
        return "Interview script completed text field is required to be four or more characters long.";
    } else if (errorMsg.error !== undefined) {
      return "Fitness Criteria name already exists. Enter a unique name then save.";
    }
  }
}

export function setCookie(name, value, expired_at) {
  let expires = "";
  if (expired_at) {
    const date = new Date(expired_at);
    // date.setTime(date.getTime() + expired * 60 * 1000);  //  expired: min
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1)
      expires =
        "; expires=" +
        date.toLocaleString("en-US", { timeZone: "America/New_York" });
    else expires = "; expires=" + date.toString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name, admin = false) {
  if (!name.includes("prospect_")) {
    if (localStorage.getItem(admin ? "admin_user" : "user") === null)
      return null;

    const email = JSON.parse(
      localStorage.getItem(admin ? "admin_user" : "user")
    ).email;
    if (name === "access_token") name = "access_" + email;
    else if (name === "refresh_token") name = "refresh_" + email;
  }

  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

export function deleteCookie(name, admin = false) {
  if (!name.includes("prospect_")) {
    if (localStorage.getItem(admin ? "admin_user" : "user") === null)
      return null;

    const email = JSON.parse(
      localStorage.getItem(admin ? "admin_user" : "user")
    ).email;
    if (name === "access_token") name = "access_" + email;
    else if (name === "refresh_token") name = "refresh_" + email;
  }

  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

export function convertDate(dateString) {
  // Create a new Date object
  let date = dateString ? new Date(dateString) : new Date();

  // Extract the components
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
  let day = date.getDate().toString().padStart(2, "0");
  let year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year

  // Format the date as MM/DD/YY
  let formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export function getHHMMSS() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const time = `${hours}${minutes}${seconds}`;

  return time;
}

export const handleBeforeUnload = (e) => {
  e.preventDefault();
  e.returnValue = ""; // This line is necessary for most browsers to display the prompt
};

export const handleClick = (e) => {
  // window.addEventListener("beforeunload", handleBeforeUnload);
  const href =
    e.target.href === undefined
      ? "/creator-dashboard/smart-interviews"
      : e.target.href;

  // You can navigate after a delay if needed
  setTimeout(() => {
    window.location.href = href;
  }, 100);

  e.preventDefault(); // Prevent the default navigation
};

export const getDevice = (ua) => {
  var os = "";

  if (ua.includes("Win") || ua.includes("Mac")) os = "Desktop";
  else {
    if (ua.includes("iPhone") || ua.includes("iPad") || ua.includes("iPod"))
      os = "iOS";
    else os = "Android";
  }

  return os;
};

export const getBrowser = (ua) => {
  var browser = "";

  if (ua.match(/chrome|chromium|crios/i)) {
    browser = "Chrome";
  } else if (ua.match(/firefox|fxios/i)) {
    browser = "Firefox";
  } else if (ua.match(/safari/i)) {
    browser = "Safari";
  } else if (ua.match(/opr\//i)) {
    browser = "Opera";
  } else if (ua.match(/edg/i)) {
    browser = "Edge";
  } else {
    browser = "No browser detection";
  }

  return browser;
};

export const getRolePriority = (roles) => {
  let role = 100;

  roles?.forEach((r) => {
    if (rolePriority[r] < role) role = rolePriority[r];
  });

  return role;
};

export const getRole = (roles) => {
  if (roles.includes("SUPER_ADMIN")) return "Super Admin";
  if (roles.includes("ADMIN")) return "Admin";
  if (roles.includes("OWNER")) return "Owner";
  return "Collaborator";
};
